<template>
  <TTView>
    <VRow>
      <VCol>
        <ModuleNav :items="items" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { ModuleNav } from '@front.backoffice/shared';

export default {
  name: 'Dashboard',

  components: {
    ModuleNav,
  },

  inject: ['Names'],

  data() {
    return {
      items: [
        {
          id: 1,
          title: 'Приглашения',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список',
              to: { name: this.Names.R_INVITATIONS },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать',
              to: { name: this.Names.R_INVITATION_CREATE },
            },
          ],
        },
      ],
    };
  },
};
</script>
